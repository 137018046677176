<template>
	<div class="page" style="text-align: center; background-color: #1f2c3d;">
		<img :src="'./images/404.jpg'" alt="">
	</div>
</template>

<script>
	export default {

		mounted() {
			let menus = sessionStorage.getItem("menus")
			if (!menus) {
				window.location.href="/login"
			}

		}
	}
</script>

<style>
</style>
